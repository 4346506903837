import React, {useCallback, useEffect, useRef, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useMultiStepForm} from '../../../hooks/useMultiStepForm'
import Step1 from './components/compute/Step1'
import {KTIcon} from '../../../_metronic/helpers'
import {alert} from '../../modules/confirmation/BasicAlert'
import Step2 from './components/compute/Step2'
import clsx from 'clsx'
import './CorporateModelCompute.css'
import {
  ICompanyList,
  IDebtRangeCompanyList,
  IRequestComputeForm,
  RequestComputeFormInit,
} from './model'
import Step3 from './components/compute/Step3'
import Step4 from './components/compute/Step4'
import Step5 from './components/compute/Step5'
import Step6 from './components/compute/Step6'
import Step7 from './components/compute/Step7'
import axios from '../../../lib/axios'
import {Navigate, useLocation, useNavigate} from 'react-router-dom'
import ErrorAlert from '../smemodel/components/ErrorAlert'
import ListLoading from './components/ListLoading'
import LoadingOverlay from './components/compute/components/LoadingOverlay'
import {IOriginalPeersDebtRange} from './model/CorporateModelModel'
import {confirm} from '../../modules/confirmation/BasicConfirmation'
import {useIntl} from 'react-intl'
type LocationState = {
  corporate_model_no: string
  last_step: number
}

type PageState = {
  loading: boolean
  alert: boolean
  alertTitle: string
  alertMessage: string
  block_navigation: boolean
  isAutosaving: boolean
}
async function getComputeReport(payload: IRequestComputeForm) {
  // try {
  const url = '/api/model/corporate/compute'
  const response = await axios.post(url, payload)
  return response.data.data
  // } catch (err) {
  //   console.log(err)
  //   return []
  // }
}

// List of company filtered by debt range
async function getCompanyList(payload: IRequestComputeForm) {
  // try {
  const url = '/api/model/corporate/get-company-list'
  const response = await axios.post(url, payload)
  return response.data.data
  // } catch (err) {
  //   console.log(err)
  //   return []
  // }
}

async function getOriginalPeersDebtRange(payload: IRequestComputeForm) {
  // try {
  const url = '/api/model/corporate/update-range'
  const response = await axios.post(url, payload)
  return response.data.data
  // } catch (err) {
  //   console.log(err)
  //   return []
  // }
}

async function getReports(corporate_model_no: string): Promise<IRequestComputeForm> {
  // try {
  const url = '/api/model/corporate/view'
  const response = await axios.post(url, {corporate_model_no})
  return response.data.data
  // } catch (err) {
  //   console.log(err);
  //   return [];
  // }
}

async function getKlciCompanyList(): Promise<ICompanyList[]> {
  // try {
  console.log('getKlciCompanyList :: ')
  const url = '/api/model/corporate/get-klci-company'
  const response = await axios.post(url)
  return response.data.data
  // } catch (err) {
  //   console.log(err);
  //   return [];
  // }
}

// getKlci company sector list
async function getKlciCompanySectorList(): Promise<ICompanyList[]> {
  // try {
  console.log('getKlciCompanySectorList ::')
  const url = '/api/model/corporate/get-klci-company-sectors'
  const response = await axios.post(url)
  return response.data.data
  // } catch (err) {
  //   console.log(err);
  //   return [];
  // }
}

async function getMuCompanySectorList(): Promise<ICompanyList[]> {
  // try {
  console.log('getMuCompanySectorList ::')
  const url = '/api/model/corporate/get-mu-company-sectors'
  const response = await axios.post(url)
  return response.data.data
  // } catch (err) {
  //   console.log(err);
  //   return [];
  // }
}

// getMuCompanyList()
async function getMuCompanyList(): Promise<ICompanyList[]> {
  // try {
  console.log('getMuCompanyList')
  const url = '/api/model/corporate/get-mu-company'
  const response = await axios.post(url)
  return response.data.data
  // } catch (err) {
  //   console.log(err);
  //   return [];
  // }
}

async function getKlciMidTierCompanyList(): Promise<ICompanyList[]> {
  // try {
  console.log('getKlciMidTierCompanyList :: ')
  const url = '/api/model/corporate/get-klci-mid-tier-company'
  const response = await axios.post(url)
  return response.data.data
  // } catch (err) {
  //   console.log(err);
  //   return [];
  // }
}

// getKlci company sector list
async function getKlciMidTierCompanySectorList(): Promise<ICompanyList[]> {
  // try {
  console.log('getKlciMidTierCompanySectorList ::')
  const url = '/api/model/corporate/get-klci-mid-tier-company-sectors'
  const response = await axios.post(url)
  return response.data.data
  // } catch (err) {
  //   console.log(err);
  //   return [];
  // }
}

// getQualitativeFactorMatrix

async function getQualitativeFactorMatrix(): Promise<[]> {
  // try {
  console.log('getQualitativeFactorMatrix ::')
  const url = '/api/model/corporate/get-qualitative-factor-matrix'
  const response = await axios.post(url)
  return response.data.data
  // } catch (err) {
  //   console.log(err);
  //   return [];
  // }
}

async function addFinancialAnalysis(payload) {
  // try {
  const url = '/api/model/corporate/add-financial-analysis'
  const response = await axios.post(url, payload)
  return response.data.data
  // } catch (err) {
  //   console.log(err);
  //   return [];
  // }
}

async function cancelUpload(payload) {
  const url = '/api/manage-file/cancel-upload'
  const response = await axios.post(url, payload)
  return response.data.data
}

async function uploadDocument(formData: FormData) {
  console.log('formData', formData)
  const url = '/api/manage-file/upload'
  const response = await axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data.data
}

function CorporateModelCompute() {
  const intl = useIntl()
  const location = useLocation()
  let navigate = useNavigate()
  const [PageState, setPageState] = useState<PageState>({
    loading: false,
    alert: false,
    alertMessage: '',
    alertTitle: '',
    block_navigation: false,
    isAutosaving: false,
  })
  const [ComputeForm, setComputeForm] = useState<IRequestComputeForm>(RequestComputeFormInit)
  const [DebtRangeCompanyList, setDebtRangeCompanyList] = useState<IDebtRangeCompanyList[]>([
    {id: 0, name: 'Please Select', total_debt: 0},
  ])
  const [OriginalPeersDebtRange, setOriginalPeersDebtRange] = useState<IOriginalPeersDebtRange>({
    total_company: 0,
    company_list: [],
    debt_range_min: 0,
    debt_range_max: 100,
  })
  const [CompanyList, setCompanyList] = useState<ICompanyList[]>([
    {id: 0, company_name: 'Please Select'},
  ])
  const [SectorList, setSectorList] = useState<ICompanyList[]>([
    {id: 0, company_name: 'Please Select'},
  ])

  const ComputeFormRef = useRef(ComputeForm)

  const [QualitativeFactors, setQualitativeFactors] = useState<[]>([])

  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const handleChange = (value: string | number, param1: string, param2: string) => {
    setComputeForm((prevState) => {
      return {
        ...prevState,
        [param1]: {
          ...prevState[param1],
          [param2]: value,
        },
      }
    })
  }

  const handleDefaultAFS = () => {
    setComputeForm((prevState) => {
      return {
        ...prevState,
        companyFinancialAnalysis: RequestComputeFormInit.companyFinancialAnalysis,
      }
    })
  }

  const handleSubmit = () => {
    console.log('Request')
    console.log(ComputeForm)
    setPageState({
      ...PageState,
      loading: true,
      alert: false,
      alertTitle: '',
      alertMessage: '',
      block_navigation: false,
    })
    getComputeReport(ComputeForm)
      .then((response) => {
        console.log('response')
        console.log(response)
        setComputeForm(response)
        setPageState({...PageState, loading: false, alert: false, block_navigation: false})
      })
      .catch(function (error) {
        if (!error.response.data.message) {
          alert('Error fetching data')
        }
        // console.log(error)
        setPageState({
          ...PageState,
          alert: true,
          alertTitle: 'Error',
          alertMessage: error.response.data.message,
          block_navigation: true,
        })
      })
  }

  const handleSubmitNavigate = (direction = 'next', corporate_model_no, page = 0) => {
    console.log('Request')
    console.log(ComputeForm)
    setPageState({
      ...PageState,
      loading: true,
      alert: false,
      alertTitle: '',
      alertMessage: '',
      block_navigation: false,
    })
    getComputeReport(ComputeForm)
      .then((response) => {
        console.log('response')
        console.log(response)
        scrollToTop()
        setComputeForm(response)
        if (direction === 'next') {
          nextStep(corporate_model_no)
        } else if (direction === 'prev') {
          prevStep()
        } else {
          //goto
          console.log('goto >>>>>>>>>>>>>>>>>', page + 1)
          handleChange(page + 1, 'companyInformation', 'current_step')
        }
        setPageState({...PageState, loading: false, alert: false, block_navigation: false})
      })
      .catch(function (error) {
        // console.log(error)
        scrollToTop()
        setPageState({
          ...PageState,
          alert: true,
          alertTitle: 'Error',
          alertMessage: error.response.data.message,
          block_navigation: true,
        })

        //Call view API
        // getReports(corporate_model_no).then((response) => {
        //   setComputeForm(response)
        //   handleChange(currentStep + 1, 'companyInformation', 'current_step')
        // })
      })
  }

  const handleDebtRange = () => {
    setPageState({
      ...PageState,
      loading: true,
      alert: false,
      alertTitle: '',
      alertMessage: '',
      block_navigation: false,
    })
    getCompanyList(ComputeForm)
      .then((response) => {
        setDebtRangeCompanyList(response)
        setPageState({...PageState, loading: false, alert: false, block_navigation: false})
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  const handleOriginalDebtRange = () => {
    setPageState({
      ...PageState,
      loading: true,
      alert: false,
      alertTitle: '',
      alertMessage: '',
      block_navigation: false,
    })

    getOriginalPeersDebtRange(ComputeForm)
      .then((response) => {
        setOriginalPeersDebtRange(response)
        setPageState({...PageState, loading: false, alert: false, block_navigation: false})
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const handleSwitchDataSource = (value: string) => {
    if (value === 'klci') {
      getKlciCompanyList()
        .then((response) => {
          setCompanyList(response)
        })
        .catch((error) => {
          console.log(error)
        })
    }
    if (value === 'mu') {
      getMuCompanyList()
        .then((response) => {
          setCompanyList(response)
        })
        .catch((error) => {
          console.log(error)
        })
    }
    if (value === 'klci-mid-tier') {
      getKlciMidTierCompanyList()
        .then((response) => {
          setCompanyList(response)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const handleRefresh = async () => {
    const report = await getReports(ComputeForm.corporate_model_no)
    setComputeForm(report)
  }

  const handleYearlyDataChange = (
    value: string | number,
    key: number,
    param1: string = '',
    param2: string = '',
    param3: string = '',
    param4: string = ''
  ) => {
    console.log('handleYearlyDataChange')
    console.log(key)
    console.log(param1)
    console.log(param2)
    console.log(param3)
    console.log(value)
    setComputeForm((prevState) => {
      let arr = prevState.companyFinancialAnalysis?.map((data, index) => {
        if (index === key) {
          if (param1 && param2 && param3 && param4) {
            return {
              ...data,
              [param1]: {
                ...data[param1],
                [param2]: {
                  ...data[param1][param2],
                  [param3]: {
                    ...data[param1][param2][param3],
                    [param4]: value,
                  },
                },
              },
            }
          } else if (param1 && param2 && param3) {
            return {
              ...data,
              [param1]: {
                ...data[param1],
                [param2]: {
                  ...data[param1][param2],
                  [param3]: value,
                },
              },
            }
          } else if (param1 && param2) {
            return {
              ...data,
              [param1]: {
                ...data[param1],
                [param2]: value,
              },
            }
          } else if (param1) {
            return {
              ...data,
              [param1]: value,
            }
          }
        }
        return data
      })

      return {
        ...prevState,
        companyFinancialAnalysis: arr,
      }
    })
    console.log(value)
  }

  const handleConfirm = async () => {
    if (
      await confirm(
        intl.formatMessage({id: 'POPUP.CONFIRMATION_ACTION_IS_PERMANENT'}),
        intl.formatMessage({id: 'POPUP.YES_PROCEED'}),
        intl.formatMessage({id: 'POPUP.CANCEL'}),
        {title: intl.formatMessage({id: 'POPUP.CONFIRMATION'})}
      )
    ) {
      let i = ComputeForm.companyFinancialAnalysis?.length! - 1
      getComputeReport(ComputeForm)
        .then((response) => {
          addFinancialAnalysis({
            corporate_model_no: ComputeForm.corporate_model_no,
            fy_start_date: ComputeForm.companyFinancialAnalysis![i].fy_start_date,
            fy_end_date: ComputeForm.companyFinancialAnalysis![i].fy_end_date,
          })
            .then((response) => {
              console.log('response')
              console.log(response)
              ;(async () => {
                const report = await getReports(ComputeForm.corporate_model_no)
                setComputeForm(report)
              })()
            })
            .catch(function (error) {
              console.log(error)
              alert(error.response.data.message)
            })
        })
        .catch((error) => {
          console.log(error)
          alert(error.response.data.message)
        })
    } else {
      console.log('Cancel Add FY')
    }
  }
  const navigationEnabler = (index: number, currentStep: number): boolean => {
    if (PageState.block_navigation) {
      return false
    }
    return ComputeForm.companyInformation.last_step > index ? true : false
  }
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  // validateStep(currentStep: number)
  const validateStep = (currentStep: number) => {
    switch (currentStep) {
      case 2:
        if (ComputeForm.companyInformation.subject_source_type === 'internal') {
          if (
            ComputeForm.companyInformation.company_name === null ||
            ComputeForm.companyInformation.company_name === ''
          ) {
            alert(
              intl.formatMessage({id: 'CM.PLEASE_SELECT_YOUR_COMPANY'}),
              intl.formatMessage({id: 'POPUP.OKAY'}),
              {title: intl.formatMessage({id: 'POPUP.ALERT'})}
            )
            return false
          }
        }

        if (ComputeForm.companyInformation.subject_source_type === 'external') {
          if (
            ComputeForm.companyInformation.subject_financial_information_type === null ||
            ComputeForm.companyInformation.subject_financial_information_type === ''
          ) {
            alert(
              intl.formatMessage({id: 'CM.PLEASE_SELECT_SUBJECT_COMPANY'}),
              intl.formatMessage({id: 'POPUP.OKAY'}),
              {title: intl.formatMessage({id: 'POPUP.ALERT'})}
            )
            return false
          }
          if (ComputeForm.companyInformation.subject_financial_information_type === 'ratio') {
            if (
              ComputeForm.companyInformation.company_name === null ||
              ComputeForm.companyInformation.company_name === ''
            ) {
              alert(
                intl.formatMessage({id: 'CM.PLEASE_ENTER_EXTERNAL_COMPANY_NAME'}),
                intl.formatMessage({id: 'POPUP.OKAY'}),
                {title: intl.formatMessage({id: 'POPUP.ALERT'})}
              )
              return false
            }
          }
        }

        break
      case 3:
        console.log('validateStep 3', ComputeForm.companyInformation.peer_type)
        if (ComputeForm.companyInformation.peer_type === 'peer_sector') {
          if (
            ComputeForm.companyAnalysis.peer_sector === null ||
            ComputeForm.companyAnalysis.peer_sector.length === 0
          ) {
            alert(
              intl.formatMessage({id: 'CM.PLEASE_SELECT_PEER_SECTOR'}),
              intl.formatMessage({id: 'POPUP.OKAY'}),
              {title: intl.formatMessage({id: 'POPUP.ALERT'})}
            )
            return false
          }
        }
        if (ComputeForm.companyInformation.peer_type === 'peer_company') {
          if (
            ComputeForm.companyAnalysis.peer_company === null ||
            ComputeForm.companyAnalysis.peer_company === ''
          ) {
            alert(
              intl.formatMessage({id: 'CM.PLEASE_SELECT_PEER_COMPANY'}),
              intl.formatMessage({id: 'POPUP.OKAY'}),
              {title: intl.formatMessage({id: 'POPUP.ALERT'})}
            )
            return false
          }
        }
        break
      case 4:
      default:
        break
    }

    return true
  }

  const handleFileUpload = async () => {
    if (!selectedFile) {
      alert(
        intl.formatMessage({id: 'Please select a file first'}),
        intl.formatMessage({id: 'POPUP.OKAY'}),
        {title: intl.formatMessage({id: 'POPUP.ALERT'})}
      )
      return
    }

    // Confirmation before uploading file
    if (
      await confirm(
        intl.formatMessage({id: 'POPUP.CONFIRMATION_UPLOAD_DOCUMENT'}),
        intl.formatMessage({id: 'POPUP.YES_PROCEED'}),
        intl.formatMessage({id: 'POPUP.CANCEL'}),
        {title: intl.formatMessage({id: 'POPUP.CONFIRMATION'})}
      )
    ) {
      const formData = new FormData()
      formData.append('file', selectedFile)
      formData.append('report_no', ComputeForm.corporate_model_no)
      formData.append('report_type', 'corporate')
      formData.append('company_name', ComputeForm.companyInformation.company_name ?? '')
      formData.append('register_number', ComputeForm.corporate_model_no ?? '')

      uploadDocument(formData)
        .then((response) => {
          console.log('File uploaded successfully:', response)
          alert('File uploaded successfully!', intl.formatMessage({id: 'POPUP.OKAY'}), {
            title: intl.formatMessage({id: 'POPUP.ALERT'}),
          })
          setSelectedFile(null) // Reset the file input
          const fileInput = document.getElementById('fileInput') as HTMLInputElement
          if (fileInput) {
            fileInput.value = ''
          }
          ;(async () => {
            const report = await getReports(ComputeForm.corporate_model_no)
            setComputeForm(report)
          })()
        })
        .catch((error) => {
          console.error('Error uploading file:', error)
          alert(
            intl.formatMessage({id: error.response.data.message}),
            intl.formatMessage({id: 'POPUP.OKAY'}),
            {title: intl.formatMessage({id: 'POPUP.ALERT'})}
          )
        })
    } else {
      console.log('Cancel uploading file')
    }
  }

  const handleCancelUpload = async () => {
    setSelectedFile(null) // Reset the file input
    cancelUpload({report_no: ComputeForm.corporate_model_no, report_type: 'corporate'})
      .then((response) => {
        console.log('File upload cancelled')
        alert('File upload cancelled!', intl.formatMessage({id: 'POPUP.OKAY'}), {
          title: intl.formatMessage({id: 'POPUP.ALERT'}),
        })
        handleChange('companyInformation', 'status_c', 'created')
        ;(async () => {
          const report = await getReports(ComputeForm.corporate_model_no)
          setComputeForm(report)
        })()
      })
      .catch((error) => {
        console.error('Error cancelling upload:', error)
        alert(
          intl.formatMessage({id: error.response.data.message}),
          intl.formatMessage({id: 'POPUP.OKAY'}),
          {title: intl.formatMessage({id: 'POPUP.ALERT'})}
        )
      })
  }

  const {currentStep, step, steps, stepsTitle, nextStep, prevStep, goToStep} = useMultiStepForm(
    ComputeForm.product_key,
    [
      'Data Source',
      'My Company',
      'Benchmark Selection',
      'Financial Analysis',
      'Non-Financial Analysis',
      'hidden-page', //Summary Page
      'Result',
    ],
    [
      <Step1
        {...ComputeForm}
        handleChange={handleChange}
        handleSwitchDataSource={handleSwitchDataSource}
      />,
      <Step2
        {...ComputeForm}
        ComputeForm={ComputeForm}
        CompanyList={CompanyList}
        handleChange={handleChange}
        handleYearlyDataChange={handleYearlyDataChange}
        handleConfirm={handleConfirm}
        handleSubmit={handleSubmit}
        setPageState={setPageState}
        PageState={PageState}
        handleRefresh={handleRefresh}
        handleDefaultAFS={handleDefaultAFS}
        handleFileUpload={handleFileUpload}
        setSelectedFile={setSelectedFile}
        handleCancelUpload={handleCancelUpload}
      />,
      <Step3
        {...ComputeForm}
        CompanyList={CompanyList}
        SectorList={SectorList}
        DebtRangeCompanyList={DebtRangeCompanyList}
        OriginalPeersDebtRange={OriginalPeersDebtRange}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleDebtRange={handleDebtRange}
        handleOriginalDebtRange={handleOriginalDebtRange}
      />,
      <Step4 {...ComputeForm} handleChange={handleChange} />,
      <Step5
        {...ComputeForm}
        QualitativeFactors={QualitativeFactors}
        handleChange={handleChange}
      />,
      <Step6 {...ComputeForm} handleChange={handleChange} />,
    ]
  )

  // Autosave
  const handleAutosave = useCallback(() => {
    if (
      ComputeForm.companyInformation.autosave === 1 &&
      ComputeForm.companyInformation.current_step === 2 &&
      ComputeForm.companyInformation.subject_financial_information_type === 'afs'
    ) {
      setPageState({
        ...PageState,
        alert: false,
        alertTitle: '',
        alertMessage: '',
        isAutosaving: true,
      })
      getComputeReport(ComputeFormRef.current)
        .then((response) => {
          // Set the new state
          // setComputeForm(response)
          handleChange(response.companyInformation.updated_at, 'companyInformation', 'updated_at')
          setPageState({
            ...PageState,
            alert: false,
            alertTitle: '',
            alertMessage: '',
            isAutosaving: false,
          })
        })
        .catch((error) => {
          console.log(error.response.data.message)
          setPageState({
            ...PageState,
            alert: false,
            alertTitle: '',
            alertMessage: '',
            isAutosaving: false,
          })
        })
    }
  }, [])

  //Activate On Load Page
  useEffect(() => {
    ;(async () => {
      setPageState({
        ...PageState,
        loading: true,
        alert: false,
        alertTitle: '',
        alertMessage: '',
        block_navigation: false,
      })
      const corporate_model_no = (location.state as LocationState)?.corporate_model_no
      if (!corporate_model_no) {
        navigate('/corporate-model')
      } else {
        console.log('location.state :: ' + corporate_model_no)
        const report = await getReports(corporate_model_no)
        setComputeForm(report)
        if (report.companyInformation.data_source === 'klci') {
          const list = await getKlciCompanyList()
          setCompanyList(list)
          const sectors = await getKlciCompanySectorList()
          setSectorList(sectors)
        }

        if (report.companyInformation.data_source === 'mu') {
          const list = await getMuCompanyList()
          setCompanyList(list)
          const sectors = await getMuCompanySectorList()
          setSectorList(sectors)
        }

        if (report.companyInformation.data_source === 'klci-mid-tier') {
          const list = await getKlciMidTierCompanyList()
          setCompanyList(list)
          const sectors = await getKlciMidTierCompanySectorList()
          setSectorList(sectors)
        }

        const qualitativeFactors = await getQualitativeFactorMatrix()
        setQualitativeFactors(qualitativeFactors)

        let step_number = report.companyInformation.last_step ?? 0
        if (step_number > 5) {
          step_number = 5
        }
        goToStep(step_number)
        setPageState({...PageState, loading: false, alert: false, block_navigation: false})

        console.log('--- corporate model no ---')
        console.log(corporate_model_no)
        console.log('--- corporate model no ---')
      }
    })()

    // Autosave
    const interval = setInterval(() => {
      handleAutosave()
    }, 1 * 60 * 1000)

    return () => clearInterval(interval)
  }, [location.state, handleAutosave])

  // Autosave
  useEffect(() => {
    ComputeFormRef.current = ComputeForm
  }, [ComputeForm])

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null

    if (ComputeForm.companyInformation.status_c === 'processing') {
      interval = setInterval(() => {
        ;(async () => {
          const report = await getReports(ComputeForm.corporate_model_no)
          setComputeForm(report)

          // If status is no longer 'processing', clear the interval
          if (report.companyInformation.status_c !== 'processing') {
            alert('Your data is ready to view!')
            if (interval) clearInterval(interval)
          }
        })()
      }, 5000) // 5000 milliseconds = 5 seconds
    }

    // Cleanup function to clear the interval when component unmounts or status changes
    return () => {
      if (interval) clearInterval(interval)
    }
  }, [ComputeForm.companyInformation.status_c, ComputeForm.corporate_model_no])

  useEffect(() => {
    /* Update current_page on page render */
    handleChange(currentStep + 1, 'companyInformation', 'current_step')
  }, [currentStep])

  const handleBreadCrumbs = (key) => {
    if (key === 'corporate-model-report-1723004859') {
      return CorpModelBursaBreadCrumbs
    }

    if (key === 'corporate-model-report-marc-universe-1723878243') {
      return CorpModelMuBreadCrumbs
    }

    if (key === 'mid-tier-report-1723872854') {
      return CorpModelMidTierBreadCrumbs
    }

    if (key === 'corporate-model-report-11112024') {
      return CorpModelBreadCrumbs
    }
  }

  const CorpModelBursaBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'CM.CORP_MODEL_BURSA_LIST'}),
      path: '/corporate-model-bursa',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const CorpModelMuBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'CM.CORP_MODEL_MU_LIST'}),
      path: '/corporate-model-mu',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const CorpModelMidTierBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'CM.CORP_MODEL_MID_TIER'}),
      path: '/corporate-model-mid-tier',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const CorpModelBreadCrumbs: Array<PageLink> = [
    {
      title: intl.formatMessage({id: 'CM.CORP_MODEL'}),
      path: '/corporate-model-main',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  console.log('Parent Render State Change----------------------------', ComputeForm)
  return (
    <>
      <PageTitle breadcrumbs={handleBreadCrumbs(ComputeForm.product_key)}>
        {intl.formatMessage({id: 'CM.COMPUTE'})}
      </PageTitle>
      <div className='card'>
        <div className='card-body'>
          <div className='stepper stepper-links d-flex flex-column pt-15'>
            {PageState.loading && <LoadingOverlay />}
            <div className='stepper-nav mb-5'>
              {stepsTitle.map((title, i) => (
                <div
                  onClick={() => {
                    if (navigationEnabler(i, currentStep)) {
                      goToStep(i, ComputeForm.corporate_model_no)
                      handleSubmitNavigate('goto', ComputeForm.corporate_model_no, i)
                    }
                  }}
                  key={i}
                  className={clsx('stepper-item', {
                    current: currentStep + 1 === i + 1,
                    'visually-hidden': title === 'hidden-page',
                  })}
                  style={{cursor: navigationEnabler(i, currentStep) ? 'pointer' : 'initial'}}
                >
                  <h3 className='stepper-title'>{intl.formatMessage({id: 'CM.' + title})}</h3>
                </div>
              ))}
            </div>
            {/* Page Content */}
            <div className='mx-auto mw-800px w-100 pt-15 pb-10'>
              <ErrorAlert
                title={intl.formatMessage({
                  id: PageState.alertTitle !== '' ? PageState.alertTitle : 'NA',
                })}
                message={intl.formatMessage({
                  id: PageState.alertMessage !== '' ? PageState.alertMessage : 'NA',
                })}
                enable={PageState.alert}
                dismiss={() => setPageState({...PageState, alert: false})}
              />
              <div
                className={clsx('mb-10', {
                  'visually-hidden': ComputeForm.companyInformation.status_c !== 'processing',
                })}
              >
                <span className='spinner-border spinner-border-sm'></span> We are reading your file
                and filling up your financial analysis.
              </div>
              {step}
            </div>
            <div className='d-flex flex-stack pt-15'>
              <div className='mr-2'>
                <button
                  onClick={() => {
                    handleSubmitNavigate('prev', ComputeForm.corporate_model_no)
                  }}
                  type='button'
                  className='btn btn-lg btn-light-primary me-3'
                  disabled={ComputeForm.companyInformation.status_c === 'processing'}
                >
                  <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                  {intl.formatMessage({id: 'CM.BACK'})}
                </button>
              </div>

              <div>
                <button
                  type='button'
                  onClick={() => {
                    if (validateStep(currentStep + 1)) {
                      handleSubmitNavigate('next', ComputeForm.corporate_model_no)
                    }
                  }}
                  className='btn btn-lg btn-primary me-3'
                  disabled={ComputeForm.companyInformation.status_c === 'processing'}
                >
                  <span className='indicator-label'>
                    {currentStep === steps.length - 1
                      ? intl.formatMessage({id: 'CM.VIEW_RESULT'})
                      : intl.formatMessage({id: 'CM.CONTINUE'})}
                    <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CorporateModelCompute
