import {
  IRequestComputeForm,
  ICompanyList,
  IReactSelectOption,
  IDebtRangeCompanyList,
  TOption,
} from './CorporateModelModel'
export type { IRequestComputeForm, ICompanyList, IReactSelectOption, IDebtRangeCompanyList, TOption }
export const RequestComputeFormInit: IRequestComputeForm = {
  corporate_model_no: '',
  product_key: '',
  companyInformation: {
    company_id: '',
    company_name: '',
    status_c: '',
    data_source: '',
    subject_source_type: '',
    subject_financial_information_type: '',
    peer_type: '',
    current_step: 1,
    last_step: 1,
    autosave: 0,
  },
  companyAnalysis: {
    peer_sector: [],
    peer_company: '',
    operating_margin: null,
    ebitda_margin: null,
    debt_to_equity: null,
    debt_to_ebitda: null,
    ebitda_to_intexp: null,
    cfo_to_debt: null,
    fcf_to_debt: null,
    cfo_to_intexp: null,
    cash_ratio: null,
    cfo_growth: null,
    debt_range_min: 10,
    debt_range_max: 1000,
    data_period: 5,
    data_validation: 0,
    profitability_weight: 25,
    profitability_sub_weight_c: 100,
    leverage_weight: 25,
    leverage_sub_weight_c: 100,
    cash_flow_weight: 25,
    cash_flow_sub_weight_c: 100,
    liquidity_weight: 25,
    liquidity_sub_weight_c: 100,
    quantitative_category_weight_c: 100,
    operating_margin_weight: 50,
    ebitda_margin_weight: 50,
    debt_to_equity_weight: 50,
    debt_to_ebitda_weight: 50,
    ebitda_to_intexp_weight: 25,
    cfo_to_debt_weight: 25,
    fcf_to_netdebt_weight: 25,
    cfo_to_intexp_weight: 25,
    cash_ratio_weight: 50,
    cfo_growth_weight: 50,
    industry_profitability_weight: 25,
    industry_profitability_score: 100,
    market_position_weight: 25,
    market_position_score: 100,
    market_structure_weight: 25,
    market_structure_score: 100,
    management_weight: 25,
    management_score: 100,
    lawsuit_legal: '',
    lawsuit_legal_score: 0,
    management_planning: '',
    management_planning_score: 0,
    management_experience: '',
    management_experience_score: 0,
    operation_year: '',
    operation_year_score: 0,
    qualitative_factor_total_weight_c: 100,
    qualitative_factor_total_score_c: 400,
    qualitative_factor_weight: 50,
    quantitative_factor_weight: 50,
  },
  companyResult: {
    updated_at: '0000-01-01 00:00:00',
    company_results: {
      company_data: {
        peers: {
          total_company: 0,
          company_list: [],
          debt_range_min: 0,
          debt_range_max: 1000000000,
        },
        original_peers: {
          total_company: 0,
          company_list: [],
          debt_range_min: 0,
          debt_range_max: 1000000000,
        },
        company: {
          company_id: 0,
          company_name: '',
        },
        ratios: {
          operating_margin: {
            latest_year: 0,
            yearly_median: 0,
          },
          ebitda_margin: {
            latest_year: 0,
            yearly_median: 0,
          },
          debt_to_equity: {
            latest_year: 0,
            yearly_median: 0,
          },
          debt_to_ebitda: {
            latest_year: 0,
            yearly_median: 0,
          },
          ebitda_to_intexp: {
            latest_year: 0,
            yearly_median: 0,
          },
          cfo_to_debt: {
            latest_year: 0,
            yearly_median: 0,
          },
          fcf_to_debt: {
            latest_year: 0,
            yearly_median: 0,
          },
          cfo_to_intexp: {
            latest_year: 0,
            yearly_median: 0,
          },
          cash_ratio: {
            latest_year: 0,
            yearly_median: 0,
          },
          cfo_growth: {
            latest_year: 0,
            yearly_median: 0,
          },

          operating_margin_latest_year: 0,
          operating_margin_score: 0,
          operating_margin_grade: 'CM-10',
          operating_margin_profile: 'Very Weak',
          ebitda_margin_latest_year: 0,
          ebitda_margin_score: 0,
          ebitda_margin_grade: 'CM-10',
          ebitda_margin_profile: 'Very Weak',
          debt_to_equity_latest_year: 0,
          debt_to_equity_score: 10,
          debt_to_equity_grade: 'CM-10',
          debt_to_equity_profile: 'Very Weak',
          debt_to_ebitda_latest_year: 0,
          debt_to_ebitda_score: 0,
          debt_to_ebitda_grade: 'CM-10',
          debt_to_ebitda_profile: 'Very Weak',
          ebitda_to_intexp_latest_year: 0,
          ebitda_to_intexp_score: 0,
          ebitda_to_intexp_grade: 'CM-10',
          ebitda_to_intexp_profile: 'Very Weak',

          cfo_to_debt_latest_year: 0,
          cfo_to_debt_score: 0,
          cfo_to_debt_grade: 'CM-10',
          cfo_to_debt_profile: 'Very Weak',

          fcf_to_debt_latest_year: 0,
          fcf_to_debt_score: 0,
          fcf_to_debt_grade: 'CM-10',
          fcf_to_debt_profile: 'Very Weak',

          cfo_to_intexp_latest_year: 0,
          cfo_to_intexp_score: 0,
          cfo_to_intexp_grade: 'CM-10',
          cfo_to_intexp_profile: 'Very Weak',

          cash_ratio_latest_year: 0,
          cash_ratio_score: 0,
          cash_ratio_grade: 'CM-10',
          cash_ratio_profile: 'Very Weak',

          cfo_growth_latest_year: 0,
          cfo_growth_score: 0,
          cfo_growth_grade: 'CM-10',
          cfo_growth_profile: 'Very Weak',
        },
      },
      score: {
        final_band: '',
        final_score: 0,
      },
      sectors_ratios: {
        operating_margin: { band_medians: [], median: 0, min: 0, max: 0 },
        ebitda_margin: { band_medians: [], median: 0, min: 0, max: 0 },
        debt_to_equity: { band_medians: [], median: 0, min: 0, max: 0 },
        debt_to_ebitda: { band_medians: [], median: 0, min: 0, max: 0 },
        ebitda_to_intexp: { band_medians: [], median: 0, min: 0, max: 0 },
        cfo_to_debt: { band_medians: [], median: 0, min: 0, max: 0 },
        fcf_to_debt: { band_medians: [], median: 0, min: 0, max: 0 },
        cfo_to_intexp: { band_medians: [], median: 0, min: 0, max: 0 },
        cash_ratio: { band_medians: [], median: 0, min: 0, max: 0 },
        cfo_growth: { band_medians: [], median: 0, min: 0, max: 0 },
      },
      benchmark: [
        {
          score: 0,
          operating_margin: null,
          ebitda_margin: null,
          debt_to_equity: null,
          debt_to_ebitda: null,
          ebitda_to_intexp: null,
          cfo_to_debt: null,
          fcf_to_debt: null,
          cfo_to_intexp: null,
          cash_ratio: null,
          cfo_growth: null,
        },
      ],
      result_matrix: [],
    },
  },
  companyFinancialAnalysis: [
    {
      financial_year_c: '0',
      fy_start_date: '0',
      fy_end_date: '0',
      number_of_months_c: 0,
      incomeStatement: {
        revenue: 0,
        cost_of_sales: -0,
        is_gross_profit_c: 0,
        sga_expenses: -0,
        is_depreciation_amortization: -0,
        other_income: 0,
        other_expenses: -0,
        ebit_c: 0,
        interest_expense: -0,
        ebt_c: 0,
        tax_expense: -0,
        net_profit_c: 0,
        other_comprehensive_income: 0,
        total_comprehensive_income_c: 0,
      },
      cashFlowAnalysis: {
        profit_before_taxation_c: 0,
        adjustments: {
          cf_depreciation_amortization: 0,
          interest_expense_income: 0,
          dividend_income: 0,
          other_adjustments: -0,
        },
        operating_profit_before_wc_changes_c: 0,
        working_capital_changes: {
          cf_inventories: -0,
          cf_trade_receivables: -0,
          cf_other_receivables_prepayment: 0,
          cf_trade_payables: 0,
          cf_amount_due_to_directors_related_parties: -0,
          cf_trade_payables_accruals: 0,
          other_changes_working_capital: 0,
        },
        cash_generated_used_operating_activities_c: 0,
        other_operating_activities: 0,
        tax_paid_refund: -0,
        net_cash_generated_used_operating_activities_c: 0,
        investingActivities: {
          disposal_acquisition_ppe: -0,
          disposal_acquisition_equity_debt: 0,
          dividends_received: 0,
          interest_received: 0,
          other_investing_activities: 0,
          net_cash_generated_used_investing_activities_c: -0,
        },
        financingActivities: {
          issuance_repurchase_shares: 0,
          issuance_repayment_debt: 0,
          dividends_paid: 0,
          interest_paid: 0,
          other_financing_activities: 0,
          net_cash_generated_used_financing_activities_c: 0,
        },
        net_cce_change_c: 0,
        other_adjustments_to_ending_cash: 0,
        cce_start_fin_year: 0,
        cce_end_fin_year_c: 0,
      },
      consolidatedBalanceSheet: {
        assets: {
          ast_inventories: 0,
          ast_trade_receivables: 0,
          ast_other_receivables_prepayments: 0,
          ast_cash_and_cash_equivalents: 0,
          other_current_assets: 0,
          total_current_assets_c: 0,
          property_plant_equipment_net: 0,
          intangible_assets_net: 0,
          deferred_tax_assets: 0,
          other_non_current_assets: 0,
          ast_total_asset_c: 0,
        },
        liabilitiesAndEquity: {
          lieq_trade_payables: 0,
          short_term_borrowings: 0,
          lieq_amount_due_to_directors_related_parties: 0,
          lieq_other_payables_accruals: 0,
          lieq_other_current_liabilities: 0,
          total_current_liabilities_c: 0,
          long_term_borrowings: 0,
          deferred_tax_liabilities: 0,
          other_non_current_liabilities: 0,
          lieq_total_liabilities_c: 0,
          share_capital: 0,
          retained_earnings_losses: 0,
          other_equity: 0,
          accumulated_oci: 0,
          lieq_total_equity_c: 0,
          total_liabilities_equity_c: 0,
        },
      },
      profitabilityEfficiencyRatio: {
        revenue_c: 0,
        per_gross_profit_c: 0,
        gross_profit_margin_c: 0,
        profit_before_tax_c: 0,
        ebitda_c: 0,
        ebitda_margin_c: 0,
        net_profit_margin_c: 0,
        return_on_asset_c: 0,
        return_on_equity_c: 0,
        sales_over_total_assets_c: 0,
        asset_turnover_ratio_c: 0,
      },
      capitalStructure: {
        total_asset_c: 0,
        cs_total_liabilities_c: 0,
        cs_total_equity_c: 0,
        gearing_ratio_c: 0,
        debt_service_ratio_c: 0,
        debt_to_equity_ratio_c: 0,
      },
      cashFlowManagement: {
        cash_flow_from_operation_c: 0,
        cash_flow_from_investing_c: -0,
        cash_flow_from_financing_c: 0,
        net_cash_flow_c: 0,
        cash_and_cash_equivalents_c: 0,
        cash_ratio_c: 0,
        quick_ratio_c: 0,
        current_ratio_c: 0,
        day_receivables_c: 0,
        day_payables_c: 0,
        inventory_turnover_c: 0,
        cash_conversion_cycle_c: 0,
      },
    },
  ],
}
