import React, {useRef, useState} from 'react'
import {Form, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {KTIcon} from '../../../../../../_metronic/helpers'
import ReactToPrint from 'react-to-print'
import styled from 'styled-components'
import TransposeTable from '../../../../smemodel/components/TransposeTable'
import {TransposeLineInput} from './TransposeLineInput'
import {TransposeLineDate} from './TransposeLineDate'
import AFSPrintingHeader from '../../../../smemodel/components/AFSPrintingHeader'
import TableEqualLine from '../../../../smemodel/components/TableEqualLine'
import {LineViewer} from '../../../../smemodel/components/LineViewer'
import clsx from 'clsx'
import moment from 'moment'
import ErrorAlert from '../../../../smemodel/components/ErrorAlert'
import {LineInput} from './LineInput'
import { useIntl } from 'react-intl'

const PrintButton = styled.button`
@media print {
  display: none;
},
@page {
  size: A4 landscape;
}
body {
  transform: scale(0.8);
}

 .print-black-font {
    color: #000 !important;
  }
}  `

const FSTableData = styled.td`
  font-weight: 400;
  text-align: right;
  padding: 3px;
  min-height: 25px;
`
const FSTableDataHead = styled.td`
  font-weight: 400;
  text-align: left;
  padding: 3px;
  min-height: 25px;
  border: 0px solid white;
`
function FormAFS({
  ComputeForm,
  handleYearlyDataChange,
  handleConfirm,
  handleSubmit,
  handleChange,
  setPageState,
  PageState,
  handleFileUpload,
  setSelectedFile,
  handleCancelUpload,
}) {
  const autoGenTooltip = 'Computed by the system upon saving.'
  const consolidated_income_statement_ref = useRef<HTMLDivElement>(null)
  const consolidated_balance_sheet_ref = useRef<HTMLDivElement>(null)
  const cash_flow_statement_ref = useRef<HTMLDivElement>(null)
  const financial_highlights_ref = useRef<HTMLDivElement>(null)
  //get current time using moment.js
  const [CurrentTime, setCurrentTime] = useState(moment().format('DD/MM/YYYY, HH:mm:ss'))
  const intl = useIntl()
  const handleBeforeGetContent = () => {
    setCurrentTime(moment().format('DD/MM/YYYY, HH:mm:ss'))
    return Promise.resolve()
  }
  return (
    <>
      <ErrorAlert
        title={intl.formatMessage({ id: PageState.alertTitle !== ''? PageState.alertTitle : 'NA' })}
        message={intl.formatMessage({ id: PageState.alertMessage !== ''? PageState.alertMessage : 'NA'  })}
        enable={PageState.alert}
        dismiss={() => setPageState({...PageState, alert: false})}
      />
      <div className={clsx('mb-10', { 'visually-hidden': ComputeForm.companyInformation.status_c !== 'processing' })}>
        <span className='spinner-border spinner-border-sm'></span> We are reading your file and filling up your financial analysis.
      </div>
      <div className={clsx('mb-10', {'visually-hidden': !PageState.isAutosaving})}>
        <span className='spinner-border spinner-border-sm'></span> Autosaving...
      </div>
      <div className='row'>
        <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
          <div className='d-flex align-items-center'>
            <button className='btn btn-primary btn-sm' onClick={() => handleSubmit()}>
              Save
            </button>
            <span className='text-gray-500 fw-bolder ms-2 fs-8 px-3' style={{fontStyle: 'italic'}}>
              {ComputeForm.companyResult.updated_at &&
                'Updated at : ' +
                  moment(ComputeForm.companyResult.updated_at).format('Do MMMM YYYY hh:mm A')}
            </span>
          </div>
        </div>
        <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
          <div className='d-flex flex-column'>
            <div className='d-flex align-items-center mb-2'>
              <div className='form-check form-switch form-check-custom form-check-solid ms-2'>
                <input
                  className='form-check-input h-20px w-30px'
                  type='checkbox'
                  value={ComputeForm.companyInformation.autosave}
                  checked={ComputeForm.companyInformation.autosave === 1}
                  onChange={(e) =>
                    handleChange(e.target.checked ? 1 : 0, 'companyInformation', 'autosave')
                  }
                  id='flexSwitch20x30'
                />
                <label className='form-check-label' htmlFor='flexSwitch20x30'>
                {intl.formatMessage({ id: 'GENERAL.AUTOSAVE' })} {ComputeForm.companyInformation.autosave === 1 ? intl.formatMessage({ id: 'GENERAL.ON' }) : intl.formatMessage({ id: 'GENERAL.OFF' })}
                <OverlayTrigger
                    placement='right'
                    overlay={
                      <Tooltip id='tooltip-right' className='fs-8'>
                        • {intl.formatMessage({ id: 'GENERAL.SWITCH_ENABLE_DISABLE' })}
                        <br />
                        • {intl.formatMessage({ id: 'GENERAL.AUTOSAVE_EFFECT' })}
                        <br />
                        • {intl.formatMessage({ id: 'GENERAL.AUTOSAVE_IF_SUCCESS' })}
                        <br />
                        • {intl.formatMessage({ id: 'GENERAL.FOR_CALCULATED_CLICK_SAVE' })}
                        <br />
                      </Tooltip>
                    }
                  >
                    <span className={clsx('mt-5 mx-3', {})}>
                      <KTIcon iconType='solid' iconName='question-2' className='fs-5' />
                    </span>
                  </OverlayTrigger>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*COMPANY INFORMATION*/}
      <div className='row'>
        <div className='border-default border p-6 mb-6'>
          <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
            <div className='d-flex my-4'>
              <div className='d-flex flex-column'>
                <h3 className='mb-1'>{intl.formatMessage({id: 'COMPANY.COMPANY_INFORMATION'})}</h3>
              </div>
            </div>
          </div>
          <LineInput
            label={intl.formatMessage({id: 'COMPANY.COMPANY_NAME'})}
            type='text'
            param1='companyInformation'
            param2='company_name'
            value={ComputeForm.companyInformation.company_name}
            required={true}
            onchange={(e, p1, p2) => handleChange(e, p1, p2)}
          />
        </div>
      </div>
      
      {/* AI ASSISTANCE FILL FINANCIAL STATEMENT*/}
      <div className='card mb-5 mb-xl-10 p-10'>
        <div className="col-md-6">
        <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Upload Financial Statement Report</Form.Label>
            <Form.Control 
              type="file" 
              id='fileInput'
              className=''
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const files = e.target.files;
                if (files && files.length > 0) {
                  setSelectedFile(files[0]);
                }
              }}
              accept='.pdf'
              disabled={ComputeForm.companyInformation.status_c === 'processing'}
            />
          </Form.Group>
        </div>

        <div
          className={clsx('spinner-container', {
            'visually-hidden': ComputeForm.companyInformation.status_c !== 'processing',
          })}
        >
          <span className='spinner-border spinner-border-sm'></span> We are reading your file and
          filling up your financial analysis.
        </div>
                
        <div className='d-flex justify-content-between align-items-start flex-wrap  '>
          <div className='d-flex flex-column'>
            <div className='d-flex align-items-center'>
              <button
                className='btn btn-primary'
                disabled={ComputeForm.companyInformation.status_c === 'processing'}
                onClick={handleFileUpload}
              >
                Upload
              </button>
            </div>
          </div>
          <div className='d-flex'>
            {/* View Result Button */}
            <button
              className='btn btn-primary'
              disabled={ComputeForm.companyInformation.status_c !== 'processing'}
              onClick={handleCancelUpload}
            >
              Cancel Upload
            </button>
          </div>
        </div>
      </div>

      {/*COMPANY FINANCIAL ANALYSIS TAB*/}
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#company_financial_analysis_tab'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>{intl.formatMessage({id: 'COMPANY.FINANCIAL_ANALYSIS'})}</h3>
          </div>
        </div>

        <div id='company_financial_analysis_tab' className='collapse show'>
          <div className='card-body border-top pt-0'>
            <div className='card card-custom shadow'>
              <div className='card-body pt-0'>
                <div className='row' id='myTabContent'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex my-4'></div>

                    <div className='d-flex my-4'>
                      <OverlayTrigger
                        placement='left'
                        overlay={
                          <Tooltip id='tooltip-left' className='fs-8'>
                            {intl.formatMessage({id: 'TOOLTIP.ADD_FINANCIAL_YEARS'})}
                          </Tooltip>
                        }
                      >
                        <span
                          className={clsx('mt-3 mx-3', {
                            'visually-hidden': ComputeForm.companyFinancialAnalysis!.length > 2,
                          })}
                        >
                          <KTIcon iconType='solid' iconName='question-2' className='fs-3' />
                        </span>
                      </OverlayTrigger>
                      {/* Add Year Button */}

                      <button
                        className={clsx('btn btn-success btn-sm', {
                          'visually-hidden': ComputeForm.companyFinancialAnalysis!.length > 2,
                        })}
                        onClick={() => handleConfirm()}
                      >
                        <i className='ki-duotone ki-plus fs-4'></i>
                      </button>
                    </div>
                  </div>
                  <div className='row' style={{ overflow: 'auto' }}>
                    <TransposeTable>
                      {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                        <tr
                          className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                          key={i}
                        >
                          <td>
                            <div className='row h-50px'>
                              <div className='col-lg-6'></div>
                              <div className='col-lg-6'>
                                <div
                                  className={clsx('alert-message-wrapper', {
                                    'visually-hidden': i !== 0,
                                  })}
                                >
                                  <div className='alert d-flex bg-light-success rounded border-success border border-solid p-2'>
                                    <div className='d-flex flex-column text-light'>
                                      <span className='fs-8 text-success'>
                                        <KTIcon
                                          iconName='information-5'
                                          className='text-success me-2'
                                        />
                                        {intl.formatMessage({id: 'AFS.INSERT_LATEST_FINANCIAL_YEAR_HERE'})}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <TransposeLineInput
                            label={intl.formatMessage({id: 'AFS.FINANCIAL_YEAR'})}
                            type='text'
                            param1='financial_year_c'
                            value={companyFA}
                            list={i}
                            disabled
                            onchange={(d, p1) => handleYearlyDataChange(d, i, p1)}
                            tooltip={autoGenTooltip}
                          />
                          <TransposeLineDate
                            label={intl.formatMessage({id: 'AFS.START_DATE'})}
                            param1='fy_start_date'
                            value={companyFA}
                            list={i}
                            required
                            onchange={(d, p1) => handleYearlyDataChange(d, i, p1)}
                          />
                          <TransposeLineDate
                            label={intl.formatMessage({id: 'AFS.END_DATE'})}
                            param1='fy_end_date'
                            value={companyFA}
                            list={i}
                            required
                            onchange={(d, p1) => handleYearlyDataChange(d, i, p1)}
                          />
                          <TransposeLineInput
                            label={intl.formatMessage({id: 'AFS.NUMBER_OF_MONTHS'})}
                            type='number'
                            param1='number_of_months_c'
                            value={companyFA}
                            list={i}
                            disabled
                            onchange={(e, p1) => handleYearlyDataChange(e, i, p1)}
                            tooltip={autoGenTooltip}
                          />
                        </tr>
                      ))}
                    </TransposeTable>
                  </div>

                  {/*INCOME STATEMENT*/}
                  <div className='income-statement-wrapper border-default border p-6 mb-6'>
                    <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                      <div className='d-flex my-4'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.CONSOLIDATED_INCOME_STATEMENT'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'AFS.DATA_REQUIRED_FOR_CORRECT_RESULT'})}
                          </div>
                        </div>
                      </div>
                      <div className='d-flex my-4'>
                        <ReactToPrint
                          documentTitle='SME Model Result'
                          bodyClass='print-black-font'
                          content={() => consolidated_income_statement_ref.current}
                          trigger={() => (
                            <PrintButton type='button' className='btn btn-primary btn-sm'>
                              <KTIcon
                                iconName='printer'
                                iconType='outline'
                                className='fs-3 text-default'
                              />
                            </PrintButton>
                          )}
                          onBeforeGetContent={() => handleBeforeGetContent()}
                        />
                      </div>
                    </div>

                    <div className='row' style={{ overflow: 'auto' }}>
                      <TransposeTable>
                        {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                          <tr
                            className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                            key={i}
                          >
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.REVENUE'})}
                              type='number'
                              param1='incomeStatement'
                              param2='revenue'
                              value={companyFA}
                              onchange={(e, p1, p2, p3) => handleYearlyDataChange(e, i, p1, p2, p3)}
                              list={i}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.COST_OF_SALES'})}
                              type='number'
                              param1='incomeStatement'
                              param2='cost_of_sales'
                              value={companyFA}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.GROSS_PROFIT'})}
                              type='number'
                              param1='incomeStatement'
                              param2='is_gross_profit_c'
                              value={companyFA}
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              tooltip={autoGenTooltip}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.SGA_EXPENSES'})}
                              type='number'
                              param1='incomeStatement'
                              param2='sga_expenses'
                              value={companyFA}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.DEPRECIATION_AND_AMORTIZATION'})}
                              type='number'
                              param1='incomeStatement'
                              param2='is_depreciation_amortization'
                              value={companyFA}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.OTHER_INCOME'})}
                              type='number'
                              param1='incomeStatement'
                              param2='other_income'
                              value={companyFA}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.OTHER_EXPENSES'})}
                              type='number'
                              param1='incomeStatement'
                              param2='other_expenses'
                              value={companyFA}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.EBIT'})}
                              type='number'
                              param1='incomeStatement'
                              param2='ebit_c'
                              value={companyFA}
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              tooltip={autoGenTooltip}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.INTEREST_INCOME_EXPENSE_NET'})}
                              type='number'
                              param1='incomeStatement'
                              param2='interest_expense'
                              value={companyFA}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              long
                              tooltip={intl.formatMessage({id: 'TOOLTIP.INTEREST_INCOME_EXPENSE_NET'})}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.EBT'})}
                              type='number'
                              param1='incomeStatement'
                              param2='ebt_c'
                              value={companyFA}
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              tooltip={autoGenTooltip}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.TAX_INCOME_EXPENSE_NET'})}
                              type='number'
                              param1='incomeStatement'
                              param2='tax_expense'
                              value={companyFA}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              long
                              tooltip={intl.formatMessage({id: 'TOOLTIP.TAX_INCOME_EXPENSE_NET'})}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.NET_PROFIT_LOSS_NET'})}
                              type='number'
                              param1='incomeStatement'
                              param2='net_profit_c'
                              value={companyFA}
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              long
                              tooltip={autoGenTooltip}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.OTHER_COMPREHENSIVE_INCOME'})}
                              type='number'
                              param1='incomeStatement'
                              param2='other_comprehensive_income'
                              value={companyFA}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              tooltip={intl.formatMessage({id: 'TOOLTIP.OTHER_COMPREHENSIVE_INCOME'})}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.TOTAL_COMPREHENSIVE_INCOME_EXPENSE'})}
                              type='number'
                              param1='incomeStatement'
                              param2='total_comprehensive_income_c'
                              value={companyFA}
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              long
                              tooltip={autoGenTooltip}
                            />
                          </tr>
                        ))}
                      </TransposeTable>
                    </div>
                    {/* Printable Consolidated Income Statement */}
                    <div className='row'>
                      <div
                        ref={consolidated_income_statement_ref}
                        className='print-only card-body p-9'
                      >
                        <AFSPrintingHeader
                          Title={intl.formatMessage({id: 'AFS.CONSOLIDATED_INCOME_STATEMENT'})}
                          ComputeForm={ComputeForm}
                          CurrentTime={CurrentTime}
                        />
                        <div className='row mb-2'>
                          <table className='transpose-table'>
                            <tbody>
                              <tr className='min-w-50'>
                                <FSTableDataHead className='white-text-on-blue-bg fw-bold text-white'>
                                  &nbsp;
                                </FSTableDataHead>
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.REVENUE'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.COST_OF_SALES'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.GROSS_PROFIT'})}
                                </FSTableDataHead>
                                <FSTableDataHead>
                                  {intl.formatMessage({id: 'AFS.SGA_EXPENSES_FULL'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.DEPRECIATION_AND_AMORTIZATION'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_INCOME'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_EXPENSES'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.EBIT'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.INTEREST_INCOME_EXPENSE_NET'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.EBT'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.TAX_INCOME_EXPENSE_NET'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                {intl.formatMessage({id: 'AFS.NET_PROFIT_LOSS_NET'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_COMPREHENSIVE_INCOME'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.TOTAL_COMPREHENSIVE_INCOME_EXPENSE'})}
                                </FSTableDataHead>
                              </tr>
                              {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                                <tr style={{ minWidth: '15%' }} key={i}>
                                  <FSTableData
                                    className='white-text-on-blue-bg fw-bold text-white'
                                    style={{
                                      backgroundColor: '#36486b',
                                    }}
                                  >
                                    {companyFA.financial_year_c}
                                  </FSTableData>
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='revenue'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='cost_of_sales'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='is_gross_profit_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='sga_expenses'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='is_depreciation_amortization'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='other_income'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='other_expenses'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='ebit_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='interest_expense'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='ebt_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='tax_expense'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='net_profit_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='other_comprehensive_income'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='incomeStatement'
                                      param2='total_comprehensive_income_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*CASH FLOW ANALYSIS*/}
                  <div className='cash-flow-analysis-wrapper border-default border p-6 mb-6'>
                    <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.CASH_FLOW_STATEMENT'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'AFS.DATA_REQUIRED_FOR_CORRECT_RESULT'})}
                          </div>
                        </div>
                      </div>
                      <div className='d-flex my-4'>
                        <ReactToPrint
                          documentTitle='SME Model Result'
                          bodyClass='print-black-font'
                          content={() => cash_flow_statement_ref.current}
                          trigger={() => (
                            <PrintButton type='button' className='btn btn-primary btn-sm'>
                              <KTIcon
                                iconName='printer'
                                iconType='outline'
                                className='fs-3 text-default'
                              />
                            </PrintButton>
                          )}
                          onBeforeGetContent={() => handleBeforeGetContent()}
                        />
                      </div>
                    </div>
                    <div className='row' style={{ overflow: 'auto' }}>
                      <TransposeTable>
                        {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                          <tr
                            className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                            key={i}
                          >
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.PROFIT_LOSS_BEFORE_TAXATION'})}
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='profit_before_taxation_c'
                              value={companyFA}
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              list={i}
                              tooltip={autoGenTooltip}
                            />
                          </tr>
                        ))}
                      </TransposeTable>
                    </div>
                    {/*ADJUSTMENT*/}
                    <div className='adjustments-wrapper border-default border p-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.ADJUSTMENTS'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'AFS.DATA_REQUIRED_FOR_CORRECT_RESULT'})}
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.DEPRECIATION_AND_AMORTIZATION'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='adjustments'
                                param3='cf_depreciation_amortization'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.INTEREST_EXPENSE_INCOME'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='adjustments'
                                param3='interest_expense_income'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.DIVIDEND_INCOME'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='adjustments'
                                param3='dividend_income'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_ADJUSTMENTS'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='adjustments'
                                param3='other_adjustments'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>
                    <div className='row' style={{ overflow: 'auto' }}>
                      <TransposeTable>
                        {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                          <tr
                            className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                            key={i}
                          >
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.OPERATING_PROFIT_LOSS_BEFORE_WORKING_CAPITAL_CHANGES'})}
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='operating_profit_before_wc_changes_c'
                              value={companyFA}
                              list={i}
                              long
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              tooltip={autoGenTooltip}
                            />
                          </tr>
                        ))}
                      </TransposeTable>
                    </div>
                    {/*WORKING CAPITAL CHANGES*/}
                    <div className='working-capital-changes-wrapper border-default border p-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.WORKING_CAPITAL_CHANGES'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'AFS.DATA_REQUIRED_FOR_CORRECT_RESULT'})}
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.INVENTORIES'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='working_capital_changes'
                                param3='cf_inventories'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.TRADE_RECEIVABLES'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='working_capital_changes'
                                param3='cf_trade_receivables'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_RECEIVABLES_PREPAYMENTS'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='working_capital_changes'
                                param3='cf_other_receivables_prepayment'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.TRADE_PAYABLES'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='working_capital_changes'
                                param3='cf_trade_payables'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_PAYABLES_ACCRUALS'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='working_capital_changes'
                                param3='cf_trade_payables_accruals'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.AMOUNT_DUE_DIRECTORS_RELATED_PARTIES'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='working_capital_changes'
                                param3='cf_amount_due_to_directors_related_parties'
                                value={companyFA}
                                list={i}
                                long
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_CHANGES_WORKING_CAPITAL'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='working_capital_changes'
                                param3='other_changes_working_capital'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>
                    <div className='row' style={{ overflow: 'auto' }}>
                      <TransposeTable>
                        {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                          <tr
                            className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                            key={i}
                          >
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.CASH_GENERATED_USED_OPERATING_ACTIVITIES'})}
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='cash_generated_used_operating_activities_c'
                              value={companyFA}
                              list={i}
                              long
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              tooltip={autoGenTooltip}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.TAX_PAID_REFUND'})}
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='tax_paid_refund'
                              value={companyFA}
                              list={i}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.OTHER_OPERATING_ACTIVITIES'})}
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='other_operating_activities'
                              value={companyFA}
                              list={i}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.NET_CASH_GENERATED_USED_OPERATING_ACTIVITIES'})}
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='net_cash_generated_used_operating_activities_c'
                              value={companyFA}
                              list={i}
                              long
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              tooltip={autoGenTooltip}
                            />
                          </tr>
                        ))}
                      </TransposeTable>
                    </div>
                    {/*INVESTING ACTIVITIES*/}
                    <div className='investing-activities-wrapper border-default border p-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.CASH_FLOW_FROM_INVESTING_ACTIVITIES'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'AFS.DATA_REQUIRED_FOR_CORRECT_RESULT'})}
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.DISPOSAL_ACQUISITION_PPE'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='investingActivities'
                                param3='disposal_acquisition_ppe'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.DISPOSAL_ACQUISITION_EQUITY_DEBT'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='investingActivities'
                                param3='disposal_acquisition_equity_debt'
                                value={companyFA}
                                list={i}
                                long
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.DIVIDENDS_RECEIVED'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='investingActivities'
                                param3='dividends_received'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.INTEREST_RECEIVED'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='investingActivities'
                                param3='interest_received'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_INVESTING_ACTIVITIES'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='investingActivities'
                                param3='other_investing_activities'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.NET_CASH_GENERATED_FROM_INVESTING_ACTIVITIES'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='investingActivities'
                                param3='net_cash_generated_used_investing_activities_c'
                                value={companyFA}
                                list={i}
                                long
                                disabled
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={autoGenTooltip}
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>

                    {/*FINANCING ACTIVITIES*/}
                    <div className='financing-activities-wrapper border-default border p-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.CASH_FLOW_FROM_FINANCING_ACTIVITIES'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'AFS.DATA_REQUIRED_FOR_CORRECT_RESULT'})}
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.ISSUANCE_REPURCHASE_SHARES'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='financingActivities'
                                param3='issuance_repurchase_shares'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.ISSUANCE_REPAYMENT_DEBT'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='financingActivities'
                                param3='issuance_repayment_debt'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              {/* interest_paid
                              dividends_paid */}
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.DIVIDENDS_PAID'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='financingActivities'
                                param3='dividends_paid'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.INTEREST_PAID'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='financingActivities'
                                param3='interest_paid'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_FINANCING_ACTIVITIES'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='financingActivities'
                                param3='other_financing_activities'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.CASH_AND_CASH_EQUIVALENTS_AT_END_OF_FINANCIAL_YEAR'})}
                                type='number'
                                param1='cashFlowAnalysis'
                                param2='financingActivities'
                                param3='net_cash_generated_used_financing_activities_c'
                                value={companyFA}
                                list={i}
                                long
                                disabled
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={autoGenTooltip}
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>
                    <div className='row' style={{ overflow: 'auto' }}>
                      <TransposeTable>
                        {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                          <tr
                            className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                            key={i}
                          >
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.NET_CHANGE_IN_CASH_AND_CASH_EQUIVALENTS'})}
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='net_cce_change_c'
                              value={companyFA}
                              list={i}
                              long
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              tooltip={autoGenTooltip}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.OTHER_ADJUSTMENTS_TO_ENDING_CASH'})}
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='other_adjustments_to_ending_cash'
                              value={companyFA}
                              list={i}
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.CASH_AND_CASH_EQUIVALENTS_AT_BEGINNING_OF_FINANCIAL_YEAR'})}
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='cce_start_fin_year'
                              value={companyFA}
                              list={i}
                              long
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                            />
                            <TransposeLineInput
                              label={intl.formatMessage({id: 'AFS.CASH_AND_CASH_EQUIVALENTS_AT_END_OF_FINANCIAL_YEAR'})}
                              type='number'
                              param1='cashFlowAnalysis'
                              param2='cce_end_fin_year_c'
                              value={companyFA}
                              list={i}
                              long
                              disabled
                              onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                              tooltip={autoGenTooltip}
                            />
                          </tr>
                        ))}
                      </TransposeTable>
                    </div>
                    <div className='row'>
                      {/* Printable Cash Flow Statement */}
                      <div ref={cash_flow_statement_ref} className='print-only card-body p-9'>
                        <AFSPrintingHeader
                          Title={intl.formatMessage({id: 'AFS.CASH_FLOW_STATEMENT'})}
                          ComputeForm={ComputeForm}
                          CurrentTime={CurrentTime}
                        />

                        <div className='row mb-2'>
                          <table className='transpose-table'>
                            <tbody>
                              <tr className='min-w-50'>
                                <FSTableDataHead className='white-text-on-blue-bg fw-bold text-white'>
                                  &nbsp;
                                </FSTableDataHead>
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.PROFIT_LOSS_BEFORE_TAXATION'})}
                                </FSTableDataHead>
                                {/* Adjustment */}
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.ADJUSTMENTS'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.DEPRECIATION_AND_AMORTIZATION'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.INTEREST_EXPENSE_INCOME'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.DIVIDEND_INCOME'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_ADJUSTMENTS'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.OPERATING_PROFIT_LOSS_BEFORE_WORKING_CAPITAL_CHANGES'})}
                                </FSTableDataHead>
                                <TableEqualLine lineType='double' offset />
                                {/* Change in working capital */}
                                <FSTableDataHead className='fw-bold'>
                                  {intl.formatMessage({id: 'AFS.WORKING_CAPITAL_CHANGES'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.INVENTORIES'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.TRADE_RECEIVABLES'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_RECEIVABLES_PREPAYMENTS'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.TRADE_PAYABLES'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_PAYABLES_ACCRUALS'})}</FSTableDataHead>
                                <FSTableDataHead>
                                  {intl.formatMessage({id: 'AFS.AMOUNT_DUE_DIRECTORS_RELATED_PARTIES'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_CHANGES_WORKING_CAPITAL'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.CASH_GENERATED_USED_OPERATING_ACTIVITIES'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.TAX_PAID_REFUND'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_OPERATING_ACTIVITIES'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.NET_CASH_GENERATED_USED_OPERATING_ACTIVITIES'})}
                                </FSTableDataHead>
                                <TableEqualLine lineType='double' offset />
                                <FSTableDataHead className='fw-bold'>
                                  {intl.formatMessage({id: 'AFS.CASH_FLOW_FROM_INVESTING_ACTIVITIES'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.DISPOSAL_ACQUISITION_PPE'})}</FSTableDataHead>
                                <FSTableDataHead>
                                  {intl.formatMessage({id: 'AFS.DISPOSAL_ACQUISITION_EQUITY_DEBT'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.DIVIDENDS_RECEIVED'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.INTEREST_RECEIVED'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_INVESTING_ACTIVITIES'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold'>
                                  {intl.formatMessage({id: 'AFS.NET_CASH_GENERATED_FROM_INVESTING_ACTIVITIES'})}
                                </FSTableDataHead>
                                <TableEqualLine lineType='double' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.CASH_FLOW_FROM_FINANCING_ACTIVITIES'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.ISSUANCE_REPURCHASE_SHARES'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.ISSUANCE_REPAYMENT_DEBT'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.DIVIDENDS_PAID'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.INTEREST_PAID'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_FINANCING_ACTIVITIES'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.NET_CASH_GENERATED_FROM_FINANCING_ACTIVITIES'})}
                                </FSTableDataHead>
                                <TableEqualLine lineType='double' offset />
                                <FSTableDataHead className='fw-bold'>
                                  {intl.formatMessage({id: 'AFS.NET_INCREASE_DECREASE_CASH_AND_CASH_EQUIVALENTS'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_ADJUSTMENTS_TO_ENDING_CASH'})}</FSTableDataHead>
                                <FSTableDataHead>
                                  {intl.formatMessage({id: 'AFS.CASH_AND_CASH_EQUIVALENTS_AT_BEGINNING_OF_FINANCIAL_YEAR'})}
                                </FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold'>
                                  {intl.formatMessage({id: 'AFS.CASH_AND_CASH_EQUIVALENTS_AT_END_OF_FINANCIAL_YEAR'})}
                                </FSTableDataHead>
                                <TableEqualLine lineType='double' offset />
                              </tr>
                              {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                                <tr style={{ minWidth: '15%' }} key={i}>
                                  <FSTableData
                                    className='white-text-on-blue-bg fw-bold text-white'
                                    style={{
                                      backgroundColor: '#36486b',
                                    }}
                                  >
                                    {companyFA.financial_year_c}
                                  </FSTableData>
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='profit_before_taxation_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>&nbsp;</FSTableData>
                                  {/* Adjustment */}
                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='adjustments'
                                      param3='cf_depreciation_amortization'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='adjustments'
                                      param3='interest_expense_income'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='adjustments'
                                      param3='dividend_income'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='adjustments'
                                      param3='other_adjustments'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='operating_profit_before_wc_changes_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='double' />
                                  <FSTableData>&nbsp;</FSTableData>
                                  {/* Change in working capital */}
                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='working_capital_changes'
                                      param3='cf_inventories'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='working_capital_changes'
                                      param3='cf_trade_receivables'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='working_capital_changes'
                                      param3='cf_other_receivables_prepayment'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='working_capital_changes'
                                      param3='cf_trade_payables'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='working_capital_changes'
                                      param3='cf_trade_payables_accruals'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='working_capital_changes'
                                      param3='cf_amount_due_to_directors_related_parties'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='working_capital_changes'
                                      param3='other_changes_working_capital'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='cash_generated_used_operating_activities_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='tax_paid_refund'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='other_operating_activities'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='net_cash_generated_used_operating_activities_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='double' />

                                  <FSTableData>&nbsp;</FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='investingActivities'
                                      param3='disposal_acquisition_ppe'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='investingActivities'
                                      param3='disposal_acquisition_equity_debt'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='investingActivities'
                                      param3='dividends_received'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='investingActivities'
                                      param3='interest_received'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='investingActivities'
                                      param3='other_investing_activities'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold'>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='investingActivities'
                                      param3='net_cash_generated_used_investing_activities_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='double' />

                                  <FSTableData className='fw-bold cell-highlight'>
                                    &nbsp;
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='financingActivities'
                                      param3='issuance_repurchase_shares'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='financingActivities'
                                      param3='issuance_repayment_debt'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='financingActivities'
                                      param3='dividends_paid'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='financingActivities'
                                      param3='interest_paid'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='financingActivities'
                                      param3='other_financing_activities'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='financingActivities'
                                      param3='net_cash_generated_used_financing_activities_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='double' />

                                  <FSTableData className='fw-bold'>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='net_cce_change_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='other_adjustments_to_ending_cash'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='cce_start_fin_year'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold'>
                                    <LineViewer
                                      param1='cashFlowAnalysis'
                                      param2='cce_end_fin_year_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='double' />
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*CONSOLIDATED BALANCE SHEET*/}
                  <div className='consolidated-balance-sheet-wrapper border-default border p-6 mb-6'>
                    <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                      <div className='d-flex my-4'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.CONSOLIDATED_BALANCE_SHEET'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'AFS.DATA_REQUIRED_FOR_CORRECT_RESULT'})}
                          </div>
                        </div>
                      </div>
                      <div className='d-flex my-4'>
                        <ReactToPrint
                          documentTitle='SME Model Result'
                          bodyClass='print-black-font'
                          content={() => consolidated_balance_sheet_ref.current}
                          trigger={() => (
                            <PrintButton type='button' className='btn btn-primary btn-sm'>
                              <KTIcon
                                iconName='printer'
                                iconType='outline'
                                className='fs-3 text-default'
                              />
                            </PrintButton>
                          )}
                          onBeforeGetContent={() => handleBeforeGetContent()}
                        />
                      </div>
                    </div>

                    {/*ASSETS*/}
                    <div className='assets-wrapper border-default border p-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.ASSETS'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'AFS.DATA_REQUIRED_FOR_CORRECT_RESULT'})}
                            </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.INVENTORIES'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='ast_inventories'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.TRADE_RECEIVABLES'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='ast_trade_receivables'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_RECEIVABLES_PREPAYMENTS'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='ast_other_receivables_prepayments'
                                value={companyFA}
                                list={i}
                                long
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={intl.formatMessage({id: 'TOOLTIP.OTHER_RECEIVABLES_PREPAYMENTS'})}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.CASH_AND_CASH_EQUIVALENTS'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='ast_cash_and_cash_equivalents'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_CURRENT_ASSETS'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='other_current_assets'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.TOTAL_CURRENT_ASSETS'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='total_current_assets_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.PROPERTY_PLANT_AND_EQUIPMENT_NET'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='property_plant_equipment_net'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={intl.formatMessage({id: 'TOOLTIP.PROPERTY_PLANT_AND_EQUIPMENT_NET'})}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.INTANGIBLE_ASSETS_NET'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='intangible_assets_net'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={intl.formatMessage({id: 'TOOLTIP.INTANGIBLE_ASSETS_NET'})}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.DEFERRED_TAX_ASSETS'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='deferred_tax_assets'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_NON_CURRENT_ASSETS'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='other_non_current_assets'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.TOTAL_ASSETS'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='assets'
                                param3='ast_total_asset_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={autoGenTooltip}
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>

                    {/*LIABILITY AND EQUITY*/}
                    <div className='liabilities-and-equity-wrapper border-default border p-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.LIABILITIES_AND_EQUITY'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'AFS.DATA_REQUIRED_FOR_CORRECT_RESULT'})}
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.TRADE_PAYABLES'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='lieq_trade_payables'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.SHORT_TERM_BORROWINGS'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='short_term_borrowings'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.AMOUNT_DUE_DIRECTORS_RELATED_PARTIES'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='lieq_amount_due_to_directors_related_parties'
                                value={companyFA}
                                list={i}
                                long
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_PAYABLES_ACCRUALS'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='lieq_other_payables_accruals'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={intl.formatMessage({id: 'TOOLTIP.OTHER_PAYABLES_ACCRUALS'})}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_CURRENT_LIABILITIES'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='lieq_other_current_liabilities'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.TOTAL_CURRENT_LIABILITIES'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='total_current_liabilities_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.LONG_TERM_BORROWINGS'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='long_term_borrowings'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.DEFERRED_TAX_LIABILITIES'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='deferred_tax_liabilities'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_NON_CURRENT_LIABILITIES'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='other_non_current_liabilities'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.TOTAL_LIABILITIES'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='lieq_total_liabilities_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.SHARE_CAPITAL'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='share_capital'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.RETAINED_EARNINGS_LOSSES'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='retained_earnings_losses'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={intl.formatMessage({id: 'TOOLTIP.RETAINED_EARNINGS_LOSSES'})}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.OTHER_EQUITY'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='other_equity'
                                value={companyFA}
                                list={i}
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.ACCUMULATED_OTHER_COMPREHENSIVE_INCOME_LOSSES'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='accumulated_oci'
                                value={companyFA}
                                list={i}
                                long
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={intl.formatMessage({id: 'TOOLTIP.ACCUMULATED_OTHER_COMPREHENSIVE_INCOME_LOSSES'})}
                              />

                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.TOTAL_EQUITY'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='lieq_total_equity_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.TOTAL_LIABILITIES_EQUITY'})}
                                type='number'
                                param1='consolidatedBalanceSheet'
                                param2='liabilitiesAndEquity'
                                param3='total_liabilities_equity_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2, p3) =>
                                  handleYearlyDataChange(e, i, p1, p2, p3)
                                }
                                tooltip={autoGenTooltip}
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>
                    <div className='row'>
                      {/* Printable Consolidated Balance Sheet */}
                      <div
                        ref={consolidated_balance_sheet_ref}
                        className='print-only card-body p-9'
                      >
                        <AFSPrintingHeader
                          Title={intl.formatMessage({id: 'AFS.CONSOLIDATED_BALANCE_SHEET'})}
                          ComputeForm={ComputeForm}
                          CurrentTime={CurrentTime}
                        />
                        <div className='row mb-2'>
                          <table className='transpose-table'>
                            <tbody>
                              <tr className='min-w-50'>
                                <FSTableDataHead className='white-text-on-blue-bg fw-bold text-white'>
                                  &nbsp;
                                </FSTableDataHead>
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.ASSETS'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.INVENTORIES'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.TRADE_RECEIVABLES'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_RECEIVABLES_PREPAYMENTS'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.CASH_AND_CASH_EQUIVALENTS'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_CURRENT_ASSETS'})}</FSTableDataHead>
                                {/* Single Equal to line offset*/}
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.TOTAL_CURRENT_ASSETS'})}
                                </FSTableDataHead>
                                <FSTableDataHead>
                                  {intl.formatMessage({id: 'AFS.PROPERTY_PLANT_AND_EQUIPMENT_NET'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.INTANGIBLE_ASSETS_NET'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.DEFERRED_TAX_ASSETS'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_NON_CURRENT_ASSETS'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold cell-highlight'>
                                  {intl.formatMessage({id: 'AFS.TOTAL_ASSETS'})}
                                </FSTableDataHead>
                                <TableEqualLine lineType='double' offset />
                                <FSTableDataHead className='fw-bold'>
                                  {intl.formatMessage({id: 'AFS.LIABILITIES_AND_EQUITY'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.TRADE_PAYABLES'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.SHORT_TERM_BORROWINGS'})}</FSTableDataHead>
                                <FSTableDataHead>
                                  {intl.formatMessage({id: 'AFS.AMOUNT_DUE_DIRECTORS_RELATED_PARTIES'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_PAYABLES_ACCRUALS'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_CURRENT_LIABILITIES'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold'>
                                  {intl.formatMessage({id: 'AFS.TOTAL_CURRENT_LIABILITIES'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.LONG_TERM_BORROWINGS'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.DEFERRED_TAX_LIABILITIES'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_NON_CURRENT_LIABILITIES'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold'>
                                  {intl.formatMessage({id: 'AFS.TOTAL_LIABILITIES'})}
                                </FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.SHARE_CAPITAL'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.RETAINED_EARNINGS_LOSSES'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.OTHER_EQUITY'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.ACCUMULATED_OTHER_COMPREHENSIVE_INCOME_LOSSES'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold'>{intl.formatMessage({id: 'AFS.TOTAL_EQUITY'})}</FSTableDataHead>
                                <TableEqualLine lineType='single' offset />
                                <FSTableDataHead className='fw-bold'>
                                  {intl.formatMessage({id: 'AFS.TOTAL_LIABILITIES_EQUITY'})}
                                </FSTableDataHead>
                              </tr>
                              {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                                <tr style={{ minWidth: '15%' }} key={i}>
                                  <FSTableData
                                    className='white-text-on-blue-bg fw-bold text-white'
                                    style={{
                                      backgroundColor: '#36486b',
                                    }}
                                  >
                                    {companyFA.financial_year_c}
                                  </FSTableData>
                                  <FSTableData className='fw-bold cell-highlight'>
                                    &nbsp;
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='ast_inventories'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='ast_trade_receivables'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='ast_other_receivables_prepayments'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='ast_cash_and_cash_equivalents'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='other_current_assets'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  {/* Single Equal to line */}
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='total_current_assets_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='property_plant_equipment_net'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='intangible_assets_net'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='deferred_tax_assets'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='other_non_current_assets'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold cell-highlight'>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='assets'
                                      param3='ast_total_asset_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='double' />
                                  <FSTableData>&nbsp;</FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='lieq_trade_payables'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='short_term_borrowings'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='lieq_amount_due_to_directors_related_parties'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='lieq_other_payables_accruals'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='lieq_other_current_liabilities'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold'>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='total_current_liabilities_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='long_term_borrowings'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='deferred_tax_liabilities'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='other_non_current_liabilities'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold'>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='lieq_total_liabilities_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='share_capital'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='retained_earnings_losses'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='other_equity'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='accumulated_oci'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold'>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='lieq_total_equity_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <TableEqualLine lineType='single' />
                                  <FSTableData className='fw-bold'>
                                    <LineViewer
                                      param1='consolidatedBalanceSheet'
                                      param2='liabilitiesAndEquity'
                                      param3='total_liabilities_equity_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* KEY RATIOS */}
                  <div className='consolidated-balance-sheet-wrapper border-default border p-6 mb-6'>
                    <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                      <div className='d-flex'>
                        <h3 className='mt-6'>{intl.formatMessage({id: 'AFS.KEY_RATIOS'})}</h3>
                      </div>
                      <div className='d-flex my-4'>
                        <ReactToPrint
                          documentTitle='SME Model Result'
                          bodyClass='print-black-font'
                          content={() => financial_highlights_ref.current}
                          trigger={() => (
                            <PrintButton type='button' className='btn btn-primary btn-sm'>
                              <KTIcon
                                iconName='printer'
                                iconType='outline'
                                className='fs-3 text-default'
                              />
                            </PrintButton>
                          )}
                          onBeforeGetContent={() => handleBeforeGetContent()}
                        />
                      </div>
                    </div>
                    {/*PROFITABILITY*/}
                    <div className='profitability-efficiency-ratio-wrapper border-default border p-6 mb-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.PROFITABILITY'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'AFS.DATA_REQUIRED_FOR_CORRECT_RESULT'})}
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.GROSS_PROFIT_MARGIN'})}
                                type='number'
                                param1='profitabilityEfficiencyRatio'
                                param2='gross_profit_margin_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />

                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.EBITDA_MARGIN'})}
                                type='number'
                                param1='profitabilityEfficiencyRatio'
                                param2='ebitda_margin_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.NET_PROFIT_MARGIN'})}
                                type='number'
                                param1='profitabilityEfficiencyRatio'
                                param2='net_profit_margin_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.RETURN_ON_ASSET'})}
                                type='number'
                                param1='profitabilityEfficiencyRatio'
                                param2='return_on_asset_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.RETURN_ON_EQUITY'})}
                                type='number'
                                param1='profitabilityEfficiencyRatio'
                                param2='return_on_equity_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>
                    {/*LIQUIDITY*/}
                    <div className='cashflow-management-wrapper border-default border p-6 mb-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.LIQUIDITY'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            Data required for accuracy of results.
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.CASH_RATIO'})}
                                type='number'
                                param1='cashFlowManagement'
                                param2='cash_ratio_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.QUICK_RATIO'})}
                                type='number'
                                param1='cashFlowManagement'
                                param2='quick_ratio_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.CURRENT_RATIO'})}
                                type='number'
                                param1='cashFlowManagement'
                                param2='current_ratio_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>
                    {/*LEVERAGE*/}
                    <div className='capital-structure-wrapper border-default border p-6 mb-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.LEVERAGE'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'AFS.DATA_REQUIRED_FOR_CORRECT_RESULT'})}
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.DEBT_TO_EQUITY_RATIO'})}
                                type='number'
                                param1='capitalStructure'
                                param2='debt_to_equity_ratio_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.DEBT_SERVICE_RATIO'})}
                                type='number'
                                param1='capitalStructure'
                                param2='debt_service_ratio_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.GEARING_RATIO'})}
                                type='number'
                                param1='capitalStructure'
                                param2='gearing_ratio_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>
                    {/* EFFICIENCY */}
                    <div className='cashflow-management-wrapper border-default border p-6 mb-6'>
                      <div className='d-flex'>
                        <KTIcon
                          iconName='questionnaire-tablet'
                          className='fs-2tx text-primary me-4'
                        />
                        <div className='d-flex flex-column'>
                          <h3 className='mb-1'>{intl.formatMessage({id: 'AFS.EFFICIENCY'})}</h3>
                          <div className='fs-6 fw-bold text-gray-400'>
                            {intl.formatMessage({id: 'AFS.DATA_REQUIRED_FOR_CORRECT_RESULT'})}
                          </div>
                        </div>
                      </div>
                      <div className='row' style={{ overflow: 'auto' }}>
                        <TransposeTable>
                          {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                            <tr
                              className={clsx('cell-offset transpose-table', { 'w-500px': i === 0 })}
                              key={i}
                            >
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.DAY_RECEIVABLES'})}
                                type='number'
                                param1='cashFlowManagement'
                                param2='day_receivables_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.DAY_PAYABLES'})}
                                type='number'
                                param1='cashFlowManagement'
                                param2='day_payables_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.INVENTORY_TURNOVER'})}
                                type='number'
                                param1='cashFlowManagement'
                                param2='inventory_turnover_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.CASH_CONVERSION_CYCLE'})}
                                type='number'
                                param1='cashFlowManagement'
                                param2='cash_conversion_cycle_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                              <TransposeLineInput
                                label={intl.formatMessage({id: 'AFS.SALES_OVER_TOTAL_ASSETS'})}
                                type='number'
                                param1='profitabilityEfficiencyRatio'
                                param2='sales_over_total_assets_c'
                                value={companyFA}
                                list={i}
                                disabled
                                onchange={(e, p1, p2) => handleYearlyDataChange(e, i, p1, p2)}
                                tooltip={autoGenTooltip}
                              />
                            </tr>
                          ))}
                        </TransposeTable>
                      </div>
                    </div>
                    <div className='row'>
                      <div ref={financial_highlights_ref} className='print-only card-body p-9'>
                        <AFSPrintingHeader
                          Title={intl.formatMessage({id: 'AFS.KEY_RATIOS'})}
                          ComputeForm={ComputeForm}
                          CurrentTime={CurrentTime}
                        />
                        <div className='row mb-2'>
                          <table className='transpose-table'>
                            <tbody>
                              <tr className='min-w-50'>
                                <FSTableDataHead className='white-text-on-blue-bg fw-bold text-white'>
                                  &nbsp;
                                </FSTableDataHead>
                                <FSTableDataHead className='fw-bold'>{intl.formatMessage({id: 'AFS.PROFITABILITY'})}</FSTableDataHead>

                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.GROSS_PROFIT_MARGIN'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.EBITDA_MARGIN'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.NET_PROFIT_MARGIN'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.RETURN_ON_ASSET'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.RETURN_ON_EQUITY'})}</FSTableDataHead>
                                <FSTableDataHead>&nbsp;</FSTableDataHead>
                                <FSTableDataHead className='fw-bold'>{intl.formatMessage({id: 'AFS.LIQUIDITY'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.CASH_RATIO'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.QUICK_RATIO'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.CURRENT_RATIO'})}</FSTableDataHead>
                                <FSTableDataHead>&nbsp;</FSTableDataHead>
                                <FSTableDataHead className='fw-bold'>{intl.formatMessage({id: 'AFS.LEVERAGE'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.DEBT_TO_EQUITY_RATIO'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.DEBT_SERVICE_RATIO'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.GEARING_RATIO'})}</FSTableDataHead>
                                <FSTableDataHead>&nbsp;</FSTableDataHead>
                                <FSTableDataHead className='fw-bold'>{intl.formatMessage({id: 'AFS.EFFICIENCY'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.DAY_RECEIVABLES'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.DAY_PAYABLES'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.INVENTORY_TURNOVER'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.CASH_CONVERSION_CYCLE'})}</FSTableDataHead>
                                <FSTableDataHead>{intl.formatMessage({id: 'AFS.SALES_OVER_TOTAL_ASSETS'})}</FSTableDataHead>
                              </tr>
                              {ComputeForm.companyFinancialAnalysis?.map((companyFA, i) => (
                                <tr style={{ minWidth: '15%' }} key={i}>
                                  <FSTableData
                                    className='white-text-on-blue-bg fw-bold text-white'
                                    style={{
                                      backgroundColor: '#36486b',
                                    }}
                                  >
                                    {companyFA.financial_year_c}
                                  </FSTableData>
                                  {/* PROFITABILITY */}
                                  <FSTableData>&nbsp;</FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='profitabilityEfficiencyRatio'
                                      param2='gross_profit_margin_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='profitabilityEfficiencyRatio'
                                      param2='ebitda_margin_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='profitabilityEfficiencyRatio'
                                      param2='net_profit_margin_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='profitabilityEfficiencyRatio'
                                      param2='return_on_asset_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='profitabilityEfficiencyRatio'
                                      param2='return_on_equity_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>&nbsp;</FSTableData>
                                  <FSTableData>&nbsp;</FSTableData>
                                  {/* LIQUIDITY */}
                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowManagement'
                                      param2='cash_ratio_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowManagement'
                                      param2='quick_ratio_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowManagement'
                                      param2='current_ratio_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>&nbsp;</FSTableData>
                                  <FSTableData>&nbsp;</FSTableData>

                                  {/* LEVERAGE */}

                                  <FSTableData>
                                    <LineViewer
                                      param1='capitalStructure'
                                      param2='debt_to_equity_ratio_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='capitalStructure'
                                      param2='debt_service_ratio_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                  <FSTableData>
                                    <LineViewer
                                      param1='capitalStructure'
                                      param2='gearing_ratio_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>&nbsp;</FSTableData>
                                  <FSTableData>&nbsp;</FSTableData>

                                  {/* EFFICIENCY */}
                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowManagement'
                                      param2='day_receivables_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowManagement'
                                      param2='day_payables_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowManagement'
                                      param2='inventory_turnover_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='cashFlowManagement'
                                      param2='cash_conversion_cycle_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>

                                  <FSTableData>
                                    <LineViewer
                                      param1='profitabilityEfficiencyRatio'
                                      param2='sales_over_total_assets_c'
                                      value={companyFA}
                                    />
                                  </FSTableData>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FormAFS
